import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Dotdotdot from "react-dotdotdot";
import cx from "classnames";
import Event from "~/models/event";
import Location from "~/views/identity/location";
import Organizer from "~/views/identity/organizer";

type Props = {
  event: Event;
  className?: string;
};

function EventCard({ event, className }: Props) {
  function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "click_event_link",
        link_target: event.url,
        link_title: event.title,
      });
    }
  }

  return (
    <div className={cx(className, "grid grid-cols-9 gap-4 sm:gap-6")}>
      <div className="col-start-1 col-end-4">
        <div className="relative col-start-1 col-end-4">
          <a
            aria-label={event.title}
            href={event.url}
            rel="noopener noreferrer"
            target="_blank"
            className="group relative block max-h-full max-w-full rounded sm:rounded-lg overflow-hidden"
            onClick={(e) => onClick(e)}
            style={{
              backgroundColor: event.image.dominantColor || "",
            }}>
            {event.image && (
              <GatsbyImage
                image={getImage(event.image as any)!}
                alt={event.title}
                loading="eager"
                imgClassName="!object-contain"
                className="aspect-[1/0.5] overflow-hidden transition-all ease-in-out duration-500 group-hover:opacity-[0.85] group-hover:scale-[1.005]"
              />
            )}
            <div className="border border-neutral-90-0.6 absolute inset-0 rounded sm:rounded-lg"></div>
          </a>
        </div>
      </div>

      <div className="col-start-4 col-end-10 flex gap-4 md:gap-6">
        <div>
          <div className="text-3xs font-semibold mb-[2px]">
            {event.dateLabel}
          </div>

          <a
            aria-label={event.title}
            href={event.url}
            rel="noopener noreferrer"
            target="_blank"
            className="font-bold leading-[1.4] mb-1 block hover:underlined-link "
            onClick={(e) => onClick(e)}>
            {event.title}
          </a>

          <div className="flex gap-2">
            <div className="flex gap-1 items-center">
              <Organizer />
              <span className="text-3xs text-neutral-60 font-medium">
                {event.organizer}
              </span>
            </div>

            {event.venue && (
              <div className="hidden sm:flex gap-1 items-center">
                <Location />
                <span className="text-3xs text-neutral-60 font-medium">
                  <Dotdotdot clamp={1}>{event.venue}</Dotdotdot>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
