import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DateTime } from "luxon";
import cx from "classnames";
import Dotdotdot from "react-dotdotdot";
import Event from "~/models/event";
import Location from "~/views/identity/location";
import Organizer from "~/views/identity/organizer";

type Props = {
  event: Event;
  className?: string;
};

function EventItem({ event, className }: Props) {
  const isPastEvent = event.endAt < DateTime.now();
  const isNewEvent = event.createdAt > DateTime.now().minus({ days: 7 });

  function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "click_event_link",
        link_target: event.url,
        link_title: event.title,
      });
    }
  }

  return (
    <div
      className={cx(
        className,
        "grid grid-cols-9 gap-4 sm:gap-8 lg:gap-[42px] xl:gap-16"
      )}>
      <div className="relative col-start-1 col-end-4">
        <a
          aria-label={event.title}
          href={event.url}
          rel="noopener noreferrer"
          target="_blank"
          className="group relative block max-h-full max-w-full rounded sm:rounded-lg overflow-hidden"
          onClick={(e) => onClick(e)}
          style={{
            backgroundColor: event.image.dominantColor || "",
          }}>
          {event.image && (
            <GatsbyImage
              image={getImage(event.image as any)!}
              alt={event.title}
              loading="eager"
              imgClassName="!object-contain"
              className="aspect-[1/0.5] overflow-hidden transition-all ease-in-out duration-500 group-hover:opacity-[0.85] group-hover:scale-[1.005]"
            />
          )}
          <div className="border border-neutral-90-0.6 absolute inset-0 rounded sm:rounded-lg"></div>

          {isPastEvent && (
            <div className="bg-[rgba(0,0,0,0.2)] absolute inset-0 rounded sm:rounded-lg p-2">
              <div className="leading-3 bg-neutral-98 rounded py-[6px] px-2 text-3xs font-semibold text-neutral-60 inline-block text-center">
                終了
              </div>
            </div>
          )}

          {isNewEvent && (
            <div className="leading-3 absolute z-10 top-1 left-1 bg-white text-cyan-strong rounded py-[6px] px-2 text-3xs font-semibold inline-block text-center border border-cyan-strong shadow-black">
              新着
            </div>
          )}
        </a>
      </div>

      <div className="col-start-4 col-end-10">
        <div className="text-xs font-semibold mb-1 md:mb-2">
          {event.dateLabel}
        </div>

        <a
          aria-label={event.title}
          href={event.url}
          rel="noopener noreferrer"
          target="_blank"
          className="block md:text-2xl font-bold leading-[1.4] mb-2 md:mb-3 hover:underlined-link"
          onClick={(e) => onClick(e)}>
          {event.title}
        </a>

        <div className="flex gap-2">
          <div className="flex gap-1 items-center">
            <Organizer />
            <span className="text-3xs text-neutral-60 font-medium">
              <Dotdotdot clamp={1}>{event.organizer}</Dotdotdot>
            </span>
          </div>

          {event.venue && (
            <div className="hidden sm:flex gap-1 items-center">
              <Location />
              <span className="text-3xs text-neutral-60 font-medium">
                <Dotdotdot clamp={1}>{event.venue}</Dotdotdot>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventItem;
