import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Play from "~/views/identity/play";

type Props = {
  node?: any;
};

function Flow({ node }: Props) {
  const { title, time, autoplay, flow } = node.data.target;

  let url = `/explore/${flow.service[0].slug}/${flow.slug}?v=1`;

  if (time) {
    url += `&t=${time}`;
  }

  if (autoplay) {
    url += "&autoplay=1";
  }

  return (
    <div className="bg-neutral-99 rounded sm:rounded-lg pt-4 sm:pt-6 pb-4 px-4 sm:px-6  sm:max-w-[508px] mx-auto my-16 flex flex-col gap-3">
      <div className="bg-neutral-30 rounded sm:rounded-lg py-4 px-5">
        <div className="mb-4 relative">
          <Play className="z-10 absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none" />
          <a
            href={url}
            target="_blank"
            className="overflow-hidden rounded-lg max-w-[128px] mx-auto block transition-[transform,opacity] ease-in-out duration-500 hover:md:opacity-[0.85] hover:md:scale-[1.015]">
            <GatsbyImage
              image={getImage(flow.posters[0] as any)!}
              alt={flow.name}
              loading="eager"
            />
          </a>
        </div>
        <a
          href={url}
          target="_blank"
          className="block text-white font-semibold text-xs text-center hover:underlined-link">
          {title}
        </a>
      </div>

      <a
        href={`/explore/`}
        target="_blank"
        className="font-semibold text-3xs leading-[16px] py-2 px-3 rounded border border-neutral-90 bg-white self-end hover:highlighted-link">
        アプリデザインギャラリー
      </a>
    </div>
  );
}

export default Flow;
