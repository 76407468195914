import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Contributor from "~/models/contributor";
import X from "~/views/identity/contributor/x";
import Instagram from "~/views/identity/contributor/instagram";
import Dribbble from "~/views/identity/contributor/dribbble";
import Behance from "~/views/identity/contributor/behance";
import LinkedIn from "~/views/identity/contributor/linkedin";

type Props = {
  contributor: Contributor;
};

const roles: any = {
  Writer: "執筆",
  Editor: "編集",
  Photographer: "撮影",
};

function ContributorCard({ contributor }: Props) {
  return (
    <div className="mt-10 flex gap-4 items-start">
      {contributor.image && (
        <GatsbyImage
          image={getImage(contributor.image as any)!}
          alt={contributor.name}
          loading="eager"
          className="rounded-full w-[74px] h-[74px] shrink-0"
        />
      )}
      <div>
        <div className="flex gap-[6px] mb-2">
          <span className="text-3xs font-semibold text-neutral-60 leading-[22px]">
            {contributor.role ? roles[contributor.role] : "by"}
          </span>
          <span className="text-neutral-30 leading-[22px] font-semibold">
            {contributor.name}
          </span>
        </div>

        {contributor.biography && (
          <p className="mb-2 text-xs leading-[22px]">{contributor.biography}</p>
        )}

        {contributor.website && (
          <a
            href={contributor.website}
            rel="noopener"
            target="_blank"
            className="inline-block mb-4 text-2xs text-neutral-60 underline hover:text-cyan-strong leading-[16px]">
            {contributor.website}
          </a>
        )}

        <div className="flex gap-6 mt-4">
          {contributor.twitter && (
            <a rel="noopener" target="_blank" href={contributor.twitter}>
              <X />
            </a>
          )}
          {contributor.instagram && (
            <a rel="noopener" target="_blank" href={contributor.instagram}>
              <Instagram />
            </a>
          )}
          {contributor.behance && (
            <a rel="noopener" target="_blank" href={contributor.behance}>
              <Behance />
            </a>
          )}
          {contributor.dribbble && (
            <a rel="noopener" target="_blank" href={contributor.dribbble}>
              <Dribbble />
            </a>
          )}
          {contributor.linkedin && (
            <a rel="noopener" target="_blank" href={contributor.linkedin}>
              <LinkedIn />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContributorCard;
