import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cx from "classnames";
import Play from "~/views/identity/play";
import Flow from "~/models/flow";

type Props = {
  flow: Flow;
  className?: string;
};

function FlowPreview({ flow, className }: Props) {
  return (
    <div className={cx(className)}>
      <div className="text-3xs md:text-base pt-2 pb-2 font-semibold text-neutral-60">
        {flow.name}
      </div>

      <Link
        to={`/explore/${flow.service!.slug}/${flow.slug}/`}
        className="relative overflow-hidden border border-neutral-90-0.6 rounded-xl block transition-all ease-in-out duration-500 hover:md:opacity-[0.85] hover:md:scale-[1.015]">
        <Play className="z-10 absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none" />
        <GatsbyImage
          image={getImage(flow.posters[0] as any)!}
          alt={flow.name}
          loading="eager"
        />
      </Link>
    </div>
  );
}

export default FlowPreview;
