import React, { useEffect, useState } from "react";
import { globalHistory } from "@reach/router";
import cx from "classnames";

import useImageModal from "~/helpers/use-image-modal";
import CloseIcon from "~/views/identity/close";

function ImageModal() {
  const ref = React.useRef() as any;
  const { handleDeactivation, visible, url, tags } = useImageModal();
  const [hoveredItem, setHoveredItem] = useState("");

  const close = (e: React.MouseEvent<any, MouseEvent>) => {
    if (ref.current.contains(e.target)) return;
    handleDeactivation();
  };

  useEffect(() => {
    const unlisten = globalHistory.listen(({ action }) => {
      if (["PUSH", "POP"].indexOf(action) != -1) {
        handleDeactivation();
      }
    });

    const scrollY = window.scrollY || 0;

    if (scrollY > 0) {
      document.body.style.top = `-${scrollY}px`;
    }
    document.body.classList.add("fixed-body");

    return function cleanup() {
      unlisten();

      const scrollY = document.body.style.top;
      document.body.classList.remove("fixed-body");
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    };
  }, []);

  return (
    <div
      className={cx(
        "p-4 fixed inset-0 bg-white-transparent transition-all ease-linear duration-100 z-50 flex justify-center flex-col",
        {
          "opacity-0": !visible,
          "opacity-100": visible,
        }
      )}
      onClick={(e) => close(e)}>
      <div className="max-w-[1100px] mx-auto">
        <div
          onMouseEnter={() => setHoveredItem("close")}
          onMouseLeave={() => setHoveredItem("")}
          className="flex justify-end mb-2">
          <button onClick={(e) => close(e)}>
            <CloseIcon
              stroke={hoveredItem == "close" ? "#C8CBCC" : "#B9BEC0"}
            />
          </button>
        </div>
        <div
          ref={ref}
          className={cx("block rounded-lg isolate bg-neutral-99", {
            "border-2 border-cyan shadow-cyan": tags.indexOf("border") != -1,
            "border border-neutral-90-0.6": tags.indexOf("border") == -1,
            "max-h-[80vh]": tags.indexOf("max-height=356") != -1,
          })}
          // style={{
          //   aspectRatio: `${gatsbyImageData.width}/${gatsbyImageData.height}`,
          // }}
        >
          <img src={url} alt="" className="rounded-lg" />
        </div>
      </div>
    </div>
  );
}

export default ImageModal;
