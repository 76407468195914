import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cx from "classnames";
import findClosest from "~/helpers/find-closest";
import Service from "~/models/service";
import Flow from "~/models/flow";

type Props = {
  service: Service;
  currentFlow: Flow;
  className?: string;
};

const FlowNavigation = ({ service, currentFlow, className }: Props) => {
  const nav = React.useRef() as any;
  const [hasPreviousItem, setHasPreviousItem] = useState(false);
  const [hasNextItem, setHasNextItem] = useState(true);

  const [navigation, setNavigation] = useState({
    overflow: false,
  });

  const handleResize = () => {
    setNavigation({
      overflow: nav.current.scrollWidth > nav.current.offsetWidth,
    });

    const currentPos = nav.current.scrollLeft;

    const snaps = [...nav.current.children].map((el: any) => {
      return (
        el.getBoundingClientRect().left -
        [...nav.current.children][0].getBoundingClientRect().left
      );
    });

    const currentIndex = snaps.indexOf(findClosest(snaps, currentPos));

    setHasNextItem(
      nav.current.scrollWidth - snaps[currentIndex] > nav.current.offsetWidth
    );
    setHasPreviousItem(currentIndex != 0);
  };

  useEffect(() => {
    const index = service.flows.findIndex((flow) => flow.id == currentFlow.id);

    const snaps = [...nav.current.children].map((el: any) => {
      return (
        el.getBoundingClientRect().left -
        [...nav.current.children][0].getBoundingClientRect().left
      );
    });

    nav.current.scrollTo({
      top: 0,
      left: snaps[index],
    });

    window.addEventListener("resize", handleResize);
    nav.current.addEventListener("scroll", handleResize);
    handleResize();

    return () => {
      if (nav.current) {
        nav.current.removeEventListener("scroll", handleResize);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const next = () => {
    if (!nav.current) return;

    const currentPos = nav.current.scrollLeft;

    const snaps = [...nav.current.children].map((el: any) => {
      return (
        el.getBoundingClientRect().left -
        [...nav.current.children][0].getBoundingClientRect().left
      );
    });

    const currentIndex = snaps.indexOf(findClosest(snaps, currentPos));

    nav.current.scrollTo({
      top: 0,
      left: snaps[currentIndex + 1],
      behavior: "smooth",
    });
  };

  const previous = () => {
    if (!nav.current) return;

    const currentPos = nav.current.scrollLeft;

    const snaps = [...nav.current.children].map((el: any) => {
      return (
        el.getBoundingClientRect().left -
        [...nav.current.children][0].getBoundingClientRect().left
      );
    });

    const currentIndex = snaps.indexOf(findClosest(snaps, currentPos));

    if (currentIndex == 0) return;

    nav.current.scrollTo({
      top: 0,
      left: snaps[currentIndex - 1],
      behavior: "smooth",
    });
  };

  return (
    <div
      onMouseEnter={() => {
        document
          .getElementsByTagName("body")[0]
          .classList.add("preventXScroll");
      }}
      onMouseLeave={() => {
        document
          .getElementsByTagName("body")[0]
          .classList.remove("preventXScroll");
      }}
      className="gap-4 sm:gap-0 flex flex-col sm:flex-row py-3 md:py-4 relative px-6 md:px-14">
      <Link
        to={`/explore/${service!.slug}/`}
        className="flex items-center gap-3 sm:dashed-b pr-6 border-r border-neutral-99">
        <GatsbyImage
          image={getImage(service!.logo as any)!}
          alt={service!.name}
          loading="eager"
          className="overflow-hidden w-8 h-8 rounded-lg border-[0.5px] border-neutral-90"
        />
        <div className="text-xl font-bold hover:highlighted-link break-keep">
          {service!.name}
        </div>
      </Link>

      <ul
        ref={nav}
        className="flex overflow-x-scroll hide-scrollbar snap-x snap-proximity pr-[76px] md:pr-11">
        {service.flows.map((f, i) => (
          <li key={f.id} className="group flex items-center snap-start">
            <Link
              className={cx(
                " py-[6px] text-xs group-first:pl-0 sm:group-first:pl-6 px-2 font-semibold break-keep whitespace-pre hover:highlighted-link",
                {
                  "text-cyan-bright": f.id == currentFlow.id,
                  "text-neutral-30": f.id != currentFlow.id,
                }
              )}
              to={`/explore/${service.slug}/${f.slug}/`}>
              {f.name}
            </Link>

            {i + 1 != service.flows.length && (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 16L14 12L10 8"
                  stroke="#E0E0E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </li>
        ))}
      </ul>

      {navigation.overflow && (
        <div className="flex absolute bottom-0 right-0 py-3 md:py-4">
          <div className="w-10 absolute white-gradient"></div>
          <button
            className={cx("bg-white px-2 border-l border-neutral-98 block", {
              "hover:bg-neutral-99": hasPreviousItem,
            })}
            onClick={() => previous()}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071L14.4142 16L19.7071 21.2929C20.0976 21.6834 20.0976 22.3166 19.7071 22.7071C19.3166 23.0976 18.6834 23.0976 18.2929 22.7071L12.2929 16.7071C11.9024 16.3166 11.9024 15.6834 12.2929 15.2929L18.2929 9.29289C18.6834 8.90237 19.3166 8.90237 19.7071 9.29289Z"
                fill={hasPreviousItem ? "#1B1B1E" : "#E0E0E0"}
              />
            </svg>
          </button>

          <button
            className={cx("bg-white px-2 border-l border-neutral-98 block", {
              "hover:bg-neutral-99": hasNextItem,
            })}
            onClick={() => next()}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2929 9.29289C12.6834 8.90237 13.3166 8.90237 13.7071 9.29289L19.7071 15.2929C20.0976 15.6834 20.0976 16.3166 19.7071 16.7071L13.7071 22.7071C13.3166 23.0976 12.6834 23.0976 12.2929 22.7071C11.9024 22.3166 11.9024 21.6834 12.2929 21.2929L17.5858 16L12.2929 10.7071C11.9024 10.3166 11.9024 9.68342 12.2929 9.29289Z"
                fill={hasNextItem ? "#1B1B1E" : "#E0E0E0"}
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default FlowNavigation;
