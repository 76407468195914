import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Window from "~/views/identity/window";
import Dotdotdot from "react-dotdotdot";
import addComma from "~/helpers/add-comma";
import formatdate from "~/helpers/formatdate";
import { DateTime } from "luxon";

type Props = {
  node?: any;
};

function Book({ node }: Props) {
  const { title, bookDescription, url, image, price, publishedAt, pr } =
    node.data.target;

  let domain = new URL(url).hostname;

  function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (!pr) return;

    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "click_affiliate_link",
        link_target: url,
        link_title: title,
      });
    }
  }

  return (
    <div className="bg-neutral-99 rounded px-4 sm:px-6 py-4 flex gap-3 sm:gap-6 items-start group my-8 sm:my-16">
      <a
        aria-label={title}
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        className="relative block shrink-0 w-[90px] sm:w-[120px] overflow-hidden isolate rounded"
        onClick={(e) => onClick(e)}>
        <GatsbyImage
          image={getImage(image as any)!}
          alt={title}
          loading="eager"
          className="transition-all ease-in-out duration-500 hover:opacity-[0.85] group-hover:scale-[1.01]"
        />
        <div className="border border-neutral-90-0.6 absolute inset-0 rounded"></div>
      </a>

      <div>
        <div className="flex justify-between items-baseline">
          <a
            aria-label={title}
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            className="block font-bold text-neutral-30 group-hover:highlighted-link"
            onClick={(e) => onClick(e)}>
            <span className="align-middle">{title}</span>
            <Window
              color="#2E2E30"
              className="align-middle inline-block ml-[6px] mb-[3px]"
            />
          </a>

          {pr && (
            <span className="relative inline-block leading-[16px] text-2xs font-bold text-neutral-60 py-[2px] px-2 bg-neutral-95 rounded align-middle ml-3 mb-1">
              PR
            </span>
          )}
        </div>

        <a
          rel="noopener noreferrer"
          target="_blank"
          className="mb-1 block text-2xs text-neutral-60 break-all"
          href={url}
          onClick={(e) => onClick(e)}>
          {domain == "amzn.to" ? "www.amazon.co.jp" : domain}
        </a>

        <div className="flex sm:gap-4 flex-col sm:flex-row">
          {!pr && (
            <div>
              <span className="text-neutral-30 leading-5 text-3xs">定価：</span>
              <span className="text-neutral-30 leading-5 font-semibold">
                {addComma(price)}
              </span>
              <span className="text-neutral-30 leading-5 text-3xs font-semibold">
                円
              </span>
            </div>
          )}

          <div>
            <span className="text-neutral-30 leading-5 text-3xs">発売日：</span>
            <span className="text-neutral-30 leading-5 text-3xs">
              {formatdate(DateTime.fromISO(publishedAt).setZone("Asia/Tokyo"))}
            </span>
          </div>
        </div>

        <a
          aria-label={title}
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          className="hidden sm:block text-xs text-neutral-30 mt-2"
          onClick={(e) => onClick(e)}>
          <Dotdotdot clamp={3}>{bookDescription.description}</Dotdotdot>
        </a>
      </div>
    </div>
  );
}

export default Book;
