import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import cx from "classnames";
import Flow from "~/models/flow";
import FlowNavigation from "./navigation";
import ShareModal from "~/views/components/flow/share";
import DownloadModal from "~/views/components/flow/download";
import videojs from "video.js";
import "video.js/dist/video-js.css";

type Props = {
  flow: Flow;
  className?: string;
};

const FlowMain = ({ flow, className }: Props) => {
  const videoRef = React.useRef(null) as any;
  const playerRef = React.useRef(null) as any;
  const [index, setIndex] = useState(0);
  const [ready, setReady] = useState(false);
  const [share, setShare] = useState(false);
  const [download, setDownload] = useState(false);
  const [time, setTime] = useState(0);
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search) as any;
  const params = Object.fromEntries(urlSearchParams.entries());
  const [playIcon, setPlayIcon] = useState(false);
  const [pauseIcon, setPauseIcon] = useState(false);
  const [startIcon, setStartIcon] = useState(
    !!params.t && params.autoplay != "1"
  );

  let videoStarted = false;

  const options = {
    controls: true,
    fluid: true,
    disablePictureInPicture: true,
    poster: !params.t ? flow.posters[0].publicUrl : "",
    sources: [
      {
        src: flow.videos[0]?.publicUrl || "",
        type: "video/mp4",
      },
    ],
    autoplay: true,
    inactivityTimeout: 0,
    languages: {
      ja: {
        Play: "再生",
        Pause: "停止",
        "Play Video": "再生",
        Mute: "ミュート",
        Unmute: "ミュート解除",
        "Playback Rate": "再生速度",
        "Picture-in-Picture": "ピクチャインピクチャ",
        Fullscreen: "全画面表示",
        "Non-Fullscreen": "通常表示",
      },
    },
    language: "ja",
  };

  let timestamps: any = [];

  const onClick = (ms: any) => {
    const t = ms.split(":");
    const seconds = parseInt(t[0]) * 60 + parseInt(t[1]);
    playerRef.current.currentTime(seconds);
  };

  const onTimeUpdate = () => {
    if (!playerRef.current) return;

    const currentTime = playerRef.current.currentTime();

    if (timestamps.indexOf(currentTime) != -1) {
      setIndex(timestamps.indexOf(currentTime));
      return;
    }

    const copy = [...timestamps];
    copy.push(currentTime);
    copy.sort(function (a, b) {
      return a - b;
    });
    const index = copy.indexOf(currentTime) - 1;
    setIndex(index);
  };

  const handleResize = () => {
    const control = document.getElementsByClassName(
      "vjs-control-bar"
    )[0] as any;

    control.style.width = `${window.innerWidth - 28}px`;
    control.style.left = `-${
      videoRef.current.getBoundingClientRect().x - 14
    }px`;
  };

  let timer = null as any;

  useEffect(() => {
    timestamps = Object.entries(flow.data["v1"].chapters).map((chapter) => {
      const t = chapter[0].split(":");
      return parseInt(t[0]) * 60 + parseInt(t[1]);
    });

    const videoElement = document.createElement("video-js");

    videoElement.classList.add(
      "video-player",
      "vjs-show-big-play-button-on-pause",
      "vjs-fill"
    );

    videoRef.current.appendChild(videoElement);

    const player = (playerRef.current = videojs(videoElement, options, () => {
      if (params.t) {
        player.currentTime(params.t);
      }

      player.muted(true);
      player.playsinline(true);
      player.autoplay(!params.t || params.autoplay == "1");

      player.on("touchstart", (e: any) => {
        if (e.target.nodeName === "VIDEO") {
          if (player.paused()) {
            player.play();
          } else {
            player.pause();
          }
        }
      });

      player.on("ready", () => {
        if (params.autoplay == "1") return;

        timer = setTimeout(() => {
          setStartIcon(true);
        }, 1000);
      });

      player.on("canplay", () => {
        setReady(true);
      });

      player.on("play", () => {
        if (player.seeking()) return;

        setStartIcon(false);
        clearTimeout(timer);
        if (!videoStarted) {
          videoStarted = true;
          return;
        }

        setPlayIcon(true);
        setTimeout(() => {
          setPlayIcon(false);
        }, 800);
      });

      player.on("pause", () => {
        if (player.seeking()) return;

        setPauseIcon(true);
        setTimeout(() => {
          setPauseIcon(false);
        }, 800);
      });

      handleResize();

      player.on("timeupdate", onTimeUpdate);
    }));

    window.addEventListener("resize", handleResize);

    return () => {
      if (playerRef.current && !playerRef.current.isDisposed()) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const screenshotUrl = () => {
    const video = document.querySelector("video") as any;
    var canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    var ctx = canvas.getContext("2d");
    ctx!.drawImage(video, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL("image/jpeg");
  };

  return (
    <div className={cx(className)}>
      <div className="overflow-hidden">
        <FlowNavigation service={flow.service!} currentFlow={flow} />

        <div className="bg-neutral-30 flex-col md:flex-row flex md:px-14 md:pb-8">
          <div className="flex justify-center pt-5 pb-4 sm:pb-16 md:py-8 px-5 md:px-8 min-[1280px]:pl-[140px] flex-1">
            <div className="w-full sm:w-auto flex flex-col sm:flex-row mx-auto gap-16 sm:gap-6">
              <div
                data-vjs-player
                className="cursor-pointer w-[240px] md:w-[290px] aspect-[1/2.166] mx-auto relative">
                {!ready && (
                  <div className="absolute inset-0 bg-[#38383B] z-10 rounded-3xl"></div>
                )}

                <div ref={videoRef} />

                {startIcon && (
                  <div
                    className="animation-start-icon absolute inset-0 z-10 rounded-3xl"
                    onClick={() => {
                      setStartIcon(false);
                      playerRef.current.play();
                    }}>
                    <svg
                      width="72"
                      height="72"
                      viewBox="0 0 72 72"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2">
                      <circle
                        opacity="0.5"
                        cx="36"
                        cy="36"
                        r="36"
                        fill="#1B1B1E"
                      />
                      <path
                        d="M54.2692 35.0007C55.0385 35.4449 55.0385 36.5551 54.2692 36.9993L27.7308 52.3213C26.9615 52.7654 26 52.2102 26 51.322L26 20.678C26 19.7898 26.9615 19.2346 27.7308 19.6788L54.2692 35.0007Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}

                {playIcon && (
                  <div className="animation-player-icon absolute inset-0 z-10 rounded-3xl pointer-events-none">
                    <svg
                      width="72"
                      height="72"
                      viewBox="0 0 72 72"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2">
                      <circle
                        opacity="0.5"
                        cx="36"
                        cy="36"
                        r="36"
                        fill="#1B1B1E"
                      />
                      <path
                        d="M54.2692 35.0007C55.0385 35.4449 55.0385 36.5551 54.2692 36.9993L27.7308 52.3213C26.9615 52.7654 26 52.2102 26 51.322L26 20.678C26 19.7898 26.9615 19.2346 27.7308 19.6788L54.2692 35.0007Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}

                {pauseIcon && (
                  <div className="animation-player-icon absolute inset-0 z-10 rounded-3xl pointer-events-none">
                    <svg
                      width="72"
                      height="72"
                      viewBox="0 0 72 72"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2">
                      <circle
                        opacity="0.5"
                        cx="36"
                        cy="36"
                        r="36"
                        fill="#1B1B1E"
                      />
                      <rect x="22" y="20" width="9" height="32" fill="white" />
                      <rect x="41" y="20" width="9" height="32" fill="white" />
                    </svg>
                  </div>
                )}
              </div>

              <div className="flex sm:justify-start sm:flex-col gap-4">
                <button
                  onClick={() => {
                    playerRef.current.pause();
                    setTime(playerRef.current.currentTime());
                    setShare(true);
                  }}
                  className="flex flex-1 sm:flex-none items-center gap-2 border border-neutral-40 rounded-lg py-[10px] px-4 bg-[rgba(88,88,91,0.25)] transition-opacity duration-200 ease-linear hover:opacity-70">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 12V15.7333C20 17.2268 20 17.9735 19.7094 18.544C19.4537 19.0457 19.0457 19.4537 18.544 19.7094C17.9735 20 17.2268 20 15.7333 20H8.26667C6.77319 20 6.02646 20 5.45603 19.7094C4.95426 19.4537 4.54631 19.0457 4.29065 18.544C4 17.9735 4 17.2268 4 15.7333V12M15.5556 7.55556L12 4M12 4L8.44444 7.55556M12 4V14.6667"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="break-keep flex-1 text-white font-medium text-xs">
                    共有
                  </span>
                </button>

                <button
                  onClick={() => {
                    playerRef.current.pause();
                    setTime(playerRef.current.currentTime());
                    setDownload(true);
                  }}
                  className="flex flex-1 sm:flex-none items-center gap-2 border border-neutral-40 rounded-lg py-[10px] px-4 bg-[rgba(88,88,91,0.25)] transition-opacity duration-200 ease-linear hover:opacity-70">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.26667 20.0001C6.77319 20.0001 6.02646 20.0001 5.45603 19.7095C4.95426 19.4538 4.54631 19.0459 4.29065 18.5441C4 17.9737 4 17.2269 4 15.7335M8.26667 4C6.77319 4 6.02646 4 5.45603 4.29065C4.95426 4.54631 4.54631 4.95426 4.29065 5.45603C4 6.02646 4 6.77319 4 8.26667M15.7333 4C17.2268 4 17.9735 4 18.544 4.29065C19.0457 4.54631 19.4537 4.95426 19.7094 5.45603C20 6.02646 20 6.77319 20 8.26667M15.7333 19.9967C17.2268 19.9967 17.9735 19.9967 18.544 19.7061C19.0457 19.4504 19.4537 19.0425 19.7094 18.5407C20 17.9703 20 17.2235 20 15.7301"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="12"
                      cy="12"
                      r="3"
                      stroke="white"
                      strokeWidth="2"
                    />
                  </svg>
                  <span className="break-keep flex-1 text-white font-medium text-xs">
                    画像として保存
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="pb-4 md:py-8 px-5 md:px-8">
            <div className="min-w-[320px] lg:min-w-[412px] py-2 bg-[rgba(88,88,91,0.25)] rounded-xl border border-neutral-40 h-full">
              <h2 className="leading-[22px] px-6 py-3 md:py-4 dashed-b-neutral-40 font-semibold text-neutral-60">
                目次
              </h2>
              <ol className="[overflow:overlay] h-[160px] md:h-[556px]">
                {Object.entries(flow.data["v1"].chapters).map(
                  (chapter: any, i) => (
                    <li
                      key={chapter[0]}
                      onClick={() => onClick(chapter[0])}
                      className={cx(
                        "cursor-pointer flex justify-between leading-[22px] px-6 py-3 md:py-4 dashed-b-neutral-40 text-white gap-2 transition-all duration-200 ease-linear",
                        {
                          "bg-neutral-30 font-semibold": i == index,
                          "hover:bg-neutral-30 opacity-30": i != index,
                        }
                      )}>
                      <div>{chapter[1]}</div>
                      <div>{chapter[0]}</div>
                    </li>
                  )
                )}
              </ol>
            </div>
          </div>
        </div>
      </div>

      {share && (
        <ShareModal
          flow={flow}
          onClose={() => setShare(false)}
          time={Math.round(time)}
          screenshot={screenshotUrl() as any}
        />
      )}

      {download && (
        <DownloadModal
          flow={flow}
          onClose={() => setDownload(false)}
          time={Math.round(time)}
          url={screenshotUrl() as any}
        />
      )}
    </div>
  );
};

export default FlowMain;
