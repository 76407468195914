import React from "react";
import { graphql } from "gatsby";
import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import Event from "~/models/event";
import NewEvents from "~/views/compositions/event/new";
import EventItem from "~/views/components/event/item";
import PageHeader from "~/views/compositions/generic/header";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.EventListPageQuery;
};

const EventListPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  const events = data.events.nodes.map(
    (event) => new Event(event as Queries.ContentfulEvent)
  );

  const byMonth = events.reduce((prev: any, event: Event) => {
    const months = [];

    for (var i = event.startAt.month; i <= event.endAt.month; i++) {
      months.push(i);
    }

    for (const mth of months) {
      const key = `${event.startAt.year}年${mth}月`;

      if (!prev[key]) {
        prev[key] = [];
      }

      prev[key].push(event);
    }

    return prev;
  }, {});

  function onClick(hash: string, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();

    var header = document.getElementById("header")!;
    var target = document.getElementById(hash)!;

    var offsetPosition =
      target.getBoundingClientRect().top +
      window.pageYOffset -
      target.getBoundingClientRect().height -
      header.getBoundingClientRect().height;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

    history.pushState({}, "", `#${hash}`);
  }

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <PageHeader
        title="デザイン関連イベントカレンダー"
        description="UI/UXデザインをはじめ、最新のデジタルデザイナー向けイベントやセミナーの開催予定をまとめています。イベント情報の掲載依頼等は問い合わせフォームからお願いします。"
      />

      <Container className="grid grid-cols-12 grid-gap-x">
        <div className="col-start-1 col-end-13 lg:col-end-10">
          <div className="xs:px-0 sm:px-0 lg:px-0 mb-14 hidden sm:flex gap-6 justify-start flex-wrap">
            {Object.keys(byMonth).map((month) => (
              <a
                key={month}
                onClick={(e) => onClick(`${month}`, e)}
                href={`#${month}`}
                className="py-3 px-5 bg-neutral-99 rounded-lg">
                <div className="text-xs font-semibold text-neutral-60">
                  {month}
                </div>
              </a>
            ))}
          </div>

          <React.Fragment>
            {Object.keys(byMonth).map((month) => (
              <div className="mb-20" key={month}>
                <h3 id={`${month}`} className="font-bold sm:text-2xl mb-8">
                  {month}開催のデザイン関連イベント一覧
                </h3>
                <ul className="grid grid-gap-y">
                  {byMonth[month].map((event: Event) => (
                    <li key={event.id}>
                      <EventItem event={event} />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </React.Fragment>
        </div>

        <div className="hidden lg:block lg:col-start-10 lg:col-end-13 mt-12 sm:mt-24 lg:mt-32">
          <NewEvents />
        </div>
      </Container>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default EventListPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  return <SEO page={page} />;
};

export const query = graphql`
  query EventListPage($today: Date) {
    page: contentfulPage(url: { pathname: { eq: "/events/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    events: allContentfulEvent(
      filter: { endAt: { gt: $today } }
      sort: { startAt: ASC }
    ) {
      nodes {
        id
        title
        organizer
        startAt
        createdAt
        endAt
        venue
        url
        image {
          gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
          dominantColor
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;
