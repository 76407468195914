import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cx from "classnames";
import findClosest from "~/helpers/find-closest";
import Service from "~/models/service";
import FlowPreview from "~/views/components/flow/preview";

type Props = {
  service: Service;
  className?: string;
};

function ServiceCard({ service, className }: Props) {
  const content = React.useRef() as any;
  const [hasPreviousItem, setHasPreviousItem] = useState(false);
  const [hasNextItem, setHasNextItem] = useState(true);

  const handleResize = () => {
    if (!content.current) return;

    const currentPos = content.current.firstChild.scrollLeft;

    const snaps = [...content.current.firstChild.children].map((el: any) => {
      return (
        el.getBoundingClientRect().left -
        [...content.current.firstChild.children][0].getBoundingClientRect().left
      );
    });

    const currentIndex = snaps.indexOf(findClosest(snaps, currentPos));

    setHasNextItem(
      content.current.firstChild.scrollWidth - snaps[currentIndex] >
        content.current.firstChild.parentNode.getBoundingClientRect().width
    );
    setHasPreviousItem(currentIndex != 0);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    content.current.firstChild.addEventListener("scroll", handleResize);
    handleResize();

    return () => {
      if (content.current) {
        content.current.firstChild.addEventListener("scroll", handleResize);
      }

      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const next = () => {
    if (!content.current) return;

    const currentPos = content.current.firstChild.scrollLeft;

    const snaps = [...content.current.firstChild.children].map((el: any) => {
      return (
        el.getBoundingClientRect().left -
        [...content.current.firstChild.children][0].getBoundingClientRect().left
      );
    });

    const currentIndex = snaps.indexOf(findClosest(snaps, currentPos));

    content.current.firstChild.scrollTo({
      top: 0,
      left: snaps[currentIndex + 1],
      behavior: "smooth",
    });
  };

  const previous = () => {
    if (!content.current) return;

    const currentPos = content.current.firstChild.scrollLeft;

    const snaps = [...content.current.firstChild.children].map((el: any) => {
      return (
        el.getBoundingClientRect().left -
        [...content.current.firstChild.children][0].getBoundingClientRect().left
      );
    });

    const currentIndex = snaps.indexOf(findClosest(snaps, currentPos));

    if (currentIndex == 0) return;

    content.current.firstChild.scrollTo({
      top: 0,
      left: snaps[currentIndex - 1],
      behavior: "smooth",
    });
  };

  return (
    <div className={cx(className, "lg:rounded-2xl bg-white pb-3")}>
      <Link
        to={`/explore/${service.slug}/`}
        className="p-5 sm:p-6 flex items-center gap-3">
        <GatsbyImage
          image={getImage(service.logo as any)!}
          alt={service.name}
          loading="eager"
          className="overflow-hidden w-11 h-11 rounded-lg border-[0.5px] border-neutral-90"
        />
        <div className="text-2xl font-bold hover:highlighted-link">
          {service.name}
        </div>
      </Link>

      <div className="relative">
        {hasPreviousItem && (
          <button
            onClick={() => previous()}
            className="hidden md:block absolute inset-y-0 left-0 bg-[rgba(248,248,248,0.2)] hover:bg-[rgba(248,248,248,0.6)] w-20 z-10">
            <svg
              width="15"
              height="28"
              viewBox="0 0 15 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2">
              <path
                d="M13.5 26L1.5 14L13.5 2"
                stroke="#1B1B1E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}

        <div ref={content}>
          <ul
            onMouseEnter={() => {
              document
                .getElementsByTagName("body")[0]
                .classList.add("preventXScroll");
            }}
            onMouseLeave={() => {
              document
                .getElementsByTagName("body")[0]
                .classList.remove("preventXScroll");
            }}
            className="flex gap-2 sm:gap-6 pb-3 mx-auto overflow-x-auto px-5 sm:px-6 hide-scrollbar snap-x snap-proximity scroll-px-5 md:scroll-px-6">
            {service.flows.map((flow) => (
              <li
                key={flow.id}
                className="shrink-0 w-[180px] sm:min-w-[238px] snap-start">
                <FlowPreview flow={flow} />
              </li>
            ))}
          </ul>
        </div>

        {hasNextItem && (
          <button
            onClick={() => next()}
            className="hidden md:block absolute inset-y-0 right-0 bg-[rgba(248,248,248,0.2)] hover:bg-[rgba(248,248,248,0.6)] w-20 z-10">
            <svg
              width="15"
              height="28"
              viewBox="0 0 15 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2">
              <path
                d="M1.5 26L13.5 14L1.5 2"
                stroke="#1B1B1E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export default ServiceCard;
