export interface ImageModal {
  mounted: boolean;
  visible: boolean;
  url: string | null;
}

export enum IMAGE_MODAL {
  MOUNT = "IMAGE_MODAL_MOUNT",
  UNMOUNT = "IMAGE_MODAL_UNMOUNT",
  OPEN = "IMAGE_MODAL_OPEN",
  CLOSE = "IMAGE_MODAL_CLOSE",
}
