import React, { useEffect, useState } from "react";
import Flow from "~/models/flow";
import Close from "~/views/identity/close";
import X from "~/views/identity/social/x";
import Facebook from "~/views/identity/social/facebook";
import LinkedIn from "~/views/identity/social/linkedin";
import useFlash from "~/helpers/use-flash";

type Props = {
  flow: Flow;
  className?: string;
  onClose: any;
  time: number;
  screenshot: string;
};

const ShareModal = ({ flow, onClose, time, screenshot }: Props) => {
  const { handleActivation } = useFlash();

  const flowUrl = `${process.env.GATSBY_SITE_URL}/explore/${
    flow.service!.slug
  }/${flow.slug}?v=1`;

  const ref = React.useRef() as any;
  const [url, setUrl] = useState(flowUrl);

  function onShare(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    method: string
  ) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "share",
        method,
      });
    }
  }

  useEffect(() => {
    const scrollY = window.scrollY || 0;

    document.body.style.top = `-${scrollY}px`;
    document.body.classList.add("fixed-body");

    return function cleanup() {
      document.body.classList.remove("fixed-body");
      const scrollY = document.body.style.top;
      document.body.classList.remove("fixed-body");
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    };
  }, []);

  return (
    <div
      onClick={(e) => {
        if (ref.current.contains(e.target)) {
          return;
        }
        onClose();
      }}
      className="fixed flex items-center flex-row inset-0 bg-[rgba(0,0,0,0.4)] z-50">
      <div ref={ref} className="bg-white p-8 rounded-2xl mx-auto">
        <div className="flex items-center justify-between mb-6">
          <div className="text-2xl sm:text-4xl font-medium">
            アプリのフローを共有
          </div>
          <button
            onClick={() => {
              onClose();
            }}>
            <Close />
          </button>
        </div>
        <div className="flex flex-col sm:flex-row gap-6">
          <img
            className="w-[112px] rounded-lg border border-neutral-90-0.6 aspect-[1/2.166] mx-auto"
            src={
              url.indexOf("t=") != -1 ? screenshot : flow.posters[0].publicUrl!
            }
            alt="プレビュー"
          />

          <div className="flex flex-col justify-center">
            <div className="flex items-center gap-2 mb-8">
              <input
                type="checkbox"
                id="time"
                name="time"
                className="cursor-pointer accent-cyan-strong"
                onChange={(e) => {
                  let u = flowUrl;

                  if (e.target.checked) {
                    u += `&t=${time}`;
                  }

                  setUrl(u);
                }}
              />
              <label
                htmlFor="time"
                className="cursor-pointer text-xs text-neutral-30">
                {`${new Date(time * 1000).toISOString().slice(14, 19)}から共有`}
              </label>
            </div>

            <div className="flex gap-2 mb-12">
              <input
                name="url"
                id="url"
                className="w-[120px] md:w-[278px] text-neutral-30 py-2 px-3 border border-neutral-90 rounded-lg outline-cyan"
                value={url}
                onChange={() => {}}
              />
              <button
                onClick={(e) => {
                  handleActivation("リンクをクリップボードにコピーしました！");
                  ref.current.getElementsByTagName("input")[1].select();
                  document.execCommand("copy");
                }}
                className="break-keep text-white py-[11px] px-4 bg-cyan-bright rounded-lg">
                リンクをコピー
              </button>
            </div>

            <div className="flex gap-3 sm:gap-5 items-center justify-center">
              <a
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  `${flow.service!.name}の${
                    flow.name
                  }  | unprinted (@unprinteddesign)`
                )}%0A%0A${encodeURIComponent(url)}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => onShare(e, "twitter")}>
                <X />
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  url
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => onShare(e, "facebook")}>
                <Facebook />
              </a>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?title=${encodeURIComponent(
                  `${flow.service!.name}の${flow.name}`
                )}&url=${encodeURIComponent(url)}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => onShare(e, "linedin")}>
                <LinkedIn />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
