export default class Company {
  id: string;
  slug: string;
  name: string;
  logo: Queries.ContentfulAsset;
  establishmentDate: string | null;
  headOfficeLocation: string | null;
  numberOfEmployees: string | null;
  links: any;

  constructor(company: Queries.ContentfulCompany) {
    this.id = company.id;
    this.slug = company.slug!;
    this.name = company.name!;
    this.logo = company.logo as any;
    this.establishmentDate = company.establishmentDate ?? null;
    this.headOfficeLocation = company.headOfficeLocation ?? null;
    this.numberOfEmployees = company.numberOfEmployees ?? null;
    this.links = company.links ?? [];
  }
}
