export default class JobPostService {
  id: string;
  name: string;
  images: Queries.ContentfulAsset[];
  url: string | null;

  constructor(service: Queries.ContentfulJobPostService) {
    this.id = service.id;
    this.name = service.name!;
    this.images = service.images as any;
    this.url = service.url ?? null;
  }
}
