import { IMAGE_MODAL, ImageModal } from "./types";

export const initialState: ImageModal = {
  mounted: false,
  visible: false,
  url: null,
};

export default function imageModal(state = initialState, action: any) {
  switch (action.type) {
    case IMAGE_MODAL.MOUNT: {
      return {
        ...state,
        mounted: true,
        url: action.url,
        tags: action.tags,
      };
    }

    case IMAGE_MODAL.UNMOUNT: {
      return {
        ...state,
        mounted: false,
        url: null,
        tags: null,
      };
    }

    case IMAGE_MODAL.OPEN: {
      return {
        ...state,
        visible: true,
      };
    }

    case IMAGE_MODAL.CLOSE: {
      return {
        ...state,
        visible: false,
      };
    }

    default: {
      return state;
    }
  }
}
