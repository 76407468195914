import { IMAGE_MODAL } from "./types";

export function open() {
  return {
    type: IMAGE_MODAL.OPEN,
  };
}

export function close() {
  return {
    type: IMAGE_MODAL.CLOSE,
  };
}

export function mount(url: any, tags: string[]) {
  return {
    type: IMAGE_MODAL.MOUNT,
    url,
    tags,
  };
}

export function unmount() {
  return {
    type: IMAGE_MODAL.UNMOUNT,
  };
}

export function handleActivation(url: any, tags: string[]) {
  return async function (dispatch: any) {
    dispatch(mount(url, tags));

    setTimeout(() => {
      dispatch(open());
    }, 0);
  };
}

export function handleDeactivation() {
  return async function (dispatch: any) {
    dispatch(close());

    setTimeout(() => {
      dispatch(unmount());
    }, 300);
  };
}
