import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import AffiliateBanner from "~/models/affiliate-banner";
import Page from "~/models/page";
import JobPost from "~/models/job-post";
import JobPostCard from "../job/job-post-card";

type Props = {
  page: Page;
  className?: string;
};

function SideNavigationBanner({ page }: Props) {
  const ref = React.useRef() as any;

  const {
    contentfulJobPosts,
    contentfulAffiliateBanner,
  }: Queries.SideNavigationBannerQuery = useStaticQuery(
    graphql`
      query SideNavigationBanner {
        contentfulAffiliateBanner(
          name: { eq: "Adobe Creative Cloud: 300x250" }
        ) {
          id
          name
          url
          code {
            code
          }
        }
        contentfulJobPosts: allContentfulJobPost(
          sort: { createdAt: DESC }
          limit: 1
        ) {
          nodes {
            ...JobPostFields
          }
          pageInfo {
            currentPage
            hasNextPage
            hasPreviousPage
            pageCount
          }
        }
      }
    `
  );

  const defaultBanner = new AffiliateBanner(
    contentfulAffiliateBanner as Queries.ContentfulAffiliateBanner
  );

  const banner =
    page.sideNavigationAdvertisements.length != 0
      ? page.sideNavigationAdvertisements[0]
      : defaultBanner;

  const jobPosts = contentfulJobPosts.nodes.map(
    (jobPost) => new JobPost(jobPost as Queries.ContentfulJobPost)
  );

  //   const banner = page.sideNavigationAdvertisements[0];
  //   if (!banner) return <div />;

  function onClick() {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "click_affiliate_banner",
        link_target: banner.url,
        link_title: "",
        link_asset: banner.name,
      });
    }
  }

  useEffect(() => {
    ref.current.getElementsByTagName("a")[0].addEventListener("click", onClick);
  }, []);

  return (
    <div ref={ref}>
      {/* <div className="mt-8">
        <div className="text-3xs font-bold text-neutral-60 mb-1 text-center">
          ピックアップ求人
        </div>
        <div>
          {jobPosts.map((post) => (
            <JobPostCard key={post.id} jobPost={post} />
          ))}
        </div>
      </div> */}

      <div key={banner.id} className="mt-8">
        <div className="text-3xs font-bold text-neutral-60 mb-1 text-center">
          PR
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: banner.code,
          }}
        />
      </div>
    </div>
  );
}

export default SideNavigationBanner;
